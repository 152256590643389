/**
 * MainMenu component that displays the main menu used for navigation.
 *
 * @module MainMenu
 */
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './Menu.css'
import Button from '../../components/ui/Button'

/**
 * MainMenu component function.
 *
 * @returns {Element} The main menu component.
 */
const MainMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  /**
   * Handles the click event for the "Create New Tosk" button.
   * Navigates to the create tosk page.
   */
  const handleCreateTosksClick = () => {
    navigate('/create-tosk', { replace: true })
  }

  /**
   * Handles the click event for the "List Tosks" button.
   * Navigates to the list tosks page.
   */
  const handleListTosksClick = () => {
    navigate('/list-tosks', { replace: true })
  }

  return (
    <div className="menu-component">
        <div className="menu-item">
            {/* Conditionally render the "Create New Tosk" button */}
            {location.pathname !== '/create-tosk' && (
            <Button
                className="primary"
                buttonText={'Create New Tosk'}
                onClick={handleCreateTosksClick}
            />
            )}
            {/* Conditionally render the "List Tosks" button */}
            {location.pathname !== '/list-tosks' && (
            <Button
                className="primary"
                buttonText={'List Tosks'}
                onClick={handleListTosksClick}
            />
            )}
        </div>
    </div>
  )
}

export default MainMenu
