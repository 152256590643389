import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/ui/Button'
import ErrorMessage from '../../components/ui/ErrorMessage'
import './RegisterPage.css'
import { AUTH_URL } from '../../config'

/**
 * Renders the register page component.
 *
 * @returns {Element} The register page component.
 */
function RegisterPage () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  /**
   * Handles changes to the email input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input event.
   */
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  /**
   * Handles changes to the password input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input event.
   */
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  /**
   * Handles the register button click event. If successful redirects to login page.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e - The button click event.
   * @returns {Promise<void>} A promise that resolves when the register request is complete.
   */
  const handleRegisterClick = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${AUTH_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
      await response.json()
      if (response.ok) {
        setErrorMessage(null)
        return navigate('/login', { replace: true })
      } else {
        setErrorMessage('Register failed')
      }
    } catch (error) {
      console.error(error)
      setErrorMessage('Register failed')
    }
  }

  /**
   * Redirects the user to the login page.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e - The button click event.
   * @returns {void}
   */
  const handleLoginClick = async (e) => {
    e.preventDefault()
    return navigate('/login', { replace: true })
  }

  return (
    <div className='register-page'>
      <div className='register-page__image'>
      </div>
      <div className='register-page__form'>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <h2>Welcome to Raitatosk</h2>
        <p>Register a free account to start creating your own tosk!</p>
        <form>
          <label>
            E-mail: <br />
            <input type="email" value={email} onChange={handleEmailChange} />
          </label>
          <label>
            Password: <br />
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
        </form>
         <Button
          buttonText="Register account"
          className="primary"
          onClick={handleRegisterClick}
        />
        <p>Already registered?
          <Button
            buttonText="Log in here!"
            className="link"
            onClick={handleLoginClick}
            />
        </p>
      </div>
    </div>
  )
}

export default RegisterPage
