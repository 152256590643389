import React from 'react'
import './SuccessMessage.css'

/**
 * SuccessMessage component displays a success message.
 *
 * @param {object} props - The props object for the message component.
 * @param {string} props.message - The success message to be displayed.
 * @returns {Element} - The JSX element for the SuccessMessage component.
 */
const SuccessMessage = ({ message }) => {
  return (
    <div className='success-message'>
      <p>{message}</p>
    </div>
  )
}

export default SuccessMessage
