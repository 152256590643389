import React from 'react'
import './UserInfo.css'

/**
 * A user info component that displays the email of the logged in user.
 *
 * @param {object} props - The props object.
 * @param {string} props.email - The email of the logged in user.
 * @returns {Element} - The rendered user info component.
 */
function UserInfo ({ email }) {
  return (
    <p><b>Logged in as:</b> {email}</p>
  )
}

export default UserInfo
