import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './Menu.css'
import Button from '../../components/ui/Button'
import ErrorMessage from '../../components/ui/ErrorMessage'
import SuccessMessage from '../../components/ui/SuccessMessage'
import { TOSK_URL } from '../../config'

/**
 * Renders a menu for saving, replacing, and deleting Tosk data.
 *
 * @param {object} props - The component props.
 * @param {string} props.headline1 - The Tosk headline1.
 * @param {string} props.headline2 - The Tosk headline2.
 * @param {string} props.headline3 - The Tosk headline3.
 * @param {string} props.ingress - The Tosk ingress.
 * @param {string} props.article - The Tosk article.
 * @param {string[]} props.keywords - The Tosk keywords.
 * @param {string[]} props.imageIDs - The Tosk image IDs.
 * @returns {Element} - The SaveMenu component.
 */
const SaveMenu = (props) => {
  const { id } = useParams()
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const headline1 = props.headline1
  const headline2 = props.headline2
  const headline3 = props.headline3
  const ingress = props.ingress
  const article = props.article
  const keywords = props.keywords
  const imageIDs = props.imageIDs

  const toskData = {
    headline1,
    headline2,
    headline3,
    ingress,
    article,
    keywords,
    imageIDs
  }

  /**
   * Handles saving of tosk data to the server.
   *
   * @param {Event} e - The event object.
   */
  const handleSave = async (e) => {
    e.preventDefault()
    const response = await fetch(`${TOSK_URL}/tosks`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({ toskData })
    })
    if (response.ok) {
      setSuccessMessage('Tosk saved successfully')
      setErrorMessage(null)
    } else {
      setErrorMessage('Something went wrong')
      setSuccessMessage(null)
    }
  }

  /**
   * Handles replacing of tosk data on the server.
   *
   * @param {Event} e - The event object.
   */
  const handleReplace = async (e) => {
    e.preventDefault()
    const response = await fetch(`${TOSK_URL}/tosks/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({ toskData })
    })
    if (response.ok) {
      setSuccessMessage('Tosk replaced successfully')
      setErrorMessage(null)
    } else {
      setErrorMessage('Something went wrong')
      setSuccessMessage(null)
    }
  }

  /**
   * Handles deleting of tosk data on the server.
   *
   * @param {Event} e - The event object.
   */
  const handleDelete = async (e) => {
    e.preventDefault()
    const response = await fetch(`${TOSK_URL}/tosks/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    })
    if (response.ok) {
      setSuccessMessage('Tosk deleted successfully')
      setErrorMessage(null)
      navigate('/list-tosks', { replace: true })
    } else {
      setErrorMessage('Something went wrong')
      setSuccessMessage(null)
    }
  }

  return (
    <div className="menu-container">
      {successMessage && <SuccessMessage message={successMessage} />}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div className="menu-item">
        <Button
          className="primary"
          buttonText={'Save Tosk'}
          onClick={handleSave}
        />
        <Button
          className="primary"
          buttonText={'Replace Tosk'}
          onClick={handleReplace}
        />
        <Button
          className="primary"
          buttonText={'Delete Tosk'}
          onClick={handleDelete}
        />
      </div>
    </div>
  )
}

export default SaveMenu
