import React from 'react'
import './ErrorMessage.css'

/**
 * A component that displays an error message.
 *
 * @param {object} props - The props object.
 * @param {string} props.message - The error message to display.
 * @returns {Element} - The JSX element for the error message.
 */
const ErrorMessage = ({ message }) => {
  return (
    <div className='error-message'>
      <p>Problem: {message}</p>
    </div>
  )
}

export default ErrorMessage
