import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MainMenu from '../Menus/MainMenu'
import SaveMenu from '../Menus/SaveMenu'
import { TOSK_URL } from '../../config'
import LogoutMenu from '../Menus/LogoutMenu'
import UserInfo from '../../components/ui/UserInfo'
import ErrorMessage from '../../components/ui/ErrorMessage'

/**
 * Renders the tosk page component.
 *
 * @returns {Element} The tosk page component.
 */
function ToskPage () {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  // const [tosk, setTosk] = useState(null)
  const [images, setImages] = useState([])
  const [headline1, setHeadline1] = useState('')
  const [headline2, setHeadline2] = useState('')
  const [headline3, setHeadline3] = useState('')
  const [ingress, setIngress] = useState('')
  const [article, setArticle] = useState('')
  const [keywords, setKeywords] = useState('')
  const [imageIDs, setImageIDs] = useState([])
  const [imagePageUrls, setImagePageUrls] = useState([])
  const [imageCreator, setImageCreator] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('access_token')
    if (!token) {
      navigate('/login', { replace: true })
    } else {
      const decodedToken = JSON.parse(atob(token.split('.')[1]))
      setEmail(decodedToken.email)
    }
  }, [navigate])

  useEffect(() => {
  /**
   * Fetches tosk from service.
   *
   * return {Promise<void>} A promise that resolves when the fetch request is complete.
   */
    const handleRetrieve = async () => {
      try {
        const response = await fetch(`${TOSK_URL}/tosks/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        })
        if (response.ok) {
          const data = await response.json()
          // setTosk(data.tosk)
          setHeadline1(data.tosk.headline1)
          setHeadline2(data.tosk.headline2)
          setHeadline3(data.tosk.headline3)
          setIngress(data.tosk.ingress)
          setArticle(data.tosk.article)
          setKeywords(data.tosk.keywords)
          setImageIDs(data.tosk.imageIDs)
          fetchImages(data.tosk.imageIDs)
          setErrorMessage(null)
        } else {
          setErrorMessage('Failed to retrieve tosk!')
        }
      } catch (error) {
        console.error(error)
        setErrorMessage('Failed to retrieve tosk!')
      }
    }
    handleRetrieve()
  }, [id])

  /**
   * Fetches imageID from service.
   *
   * @param {Array} imageIDs The imageIDs to fetch.
   * return {Promise<void>} A promise that resolves when the fetch request is complete.
   */
  const fetchImages = async (imageIDs) => {
    try {
      const imageData = await Promise.all(imageIDs.map(async (id) => {
        const response = await fetch(`${TOSK_URL}/pixabay`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          },
          body: JSON.stringify({ id })
        })

        if (response.ok) {
          const data = await response.json()
          setErrorMessage(null)
          return data.data.hits.map((hit) => ({
            webformatURL: hit.webformatURL,
            pageURL: hit.pageURL,
            user: hit.user
          }))
        } else {
          setErrorMessage('Failed to retrieve images!')
        }
      }))
      setImages(imageData.flatMap((data) => data.map((hit) => hit.webformatURL)).flat())
      setImagePageUrls(imageData.flatMap((data) => data.map((hit) => hit.pageURL)).flat())
      setImageCreator(imageData.flatMap((data) => data.map((hit) => hit.user)).flat())
    } catch (error) {
      console.error(error)
      setErrorMessage('Failed to retrieve images!')
    }
  }

  /**
   * Handles changes to the input fields.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input event.
   */
  const handleInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    const setters = {
      headline1: setHeadline1,
      headline2: setHeadline2,
      headline3: setHeadline3,
      ingress: setIngress,
      article: setArticle
    }
    const setter = setters[name]
    if (setter) {
      setter(value)
    }
  }

  return (
    <div className='tosk-page'>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div className='tosk-page-header'>
        <MainMenu />
        <h1>Tosk</h1>
        <UserInfo email={email} />
        <LogoutMenu />
      </div>

      <div className='tosk-page-body'>
        <h2>Headlines</h2>
        <textarea
          className="tosk-headline"
          name="headline1"
          value={headline1}
          onChange={handleInputChange}
        />
        <textarea
          className="tosk-headline"
          name="headline2"
          value={headline2}
          onChange={handleInputChange}
        />
        <textarea
          className="tosk-headline"
          name="headline3"
          value={headline3}
          onChange={handleInputChange}
        />

        <h2>Introduction</h2>
        <textarea
          className="tosk-ingress"
          name="ingress"
          value={ingress}
          rows={5}
          onChange={handleInputChange}
        />

        <h2>Text</h2>
        <textarea
          className="tosk-article"
          name="article"
          value={article}
          rows={20}
          onChange={handleInputChange}
        />

        <h2>Keywords</h2>
        <textarea
          className="tosk-keywords"
          value={keywords}
          rows={3}
          readOnly
        />

        <h2>Image proposals </h2>
        <div className='image-container'>
          {images.map((imageUrl, index) => (
            <div key={index} className='image-card'>
              <a href={imagePageUrls[index]} target="_blank" rel="noreferrer">
                {index + 1}. Credit: {imageCreator[index]}. <br /> Click to download!
                <img key={index} src={imageUrl} alt={`${index}`} />
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className='tosk-page-footer'>
        <SaveMenu
          headline1={headline1}
          headline2={headline2}
          headline3={headline3}
          ingress={ingress}
          article={article}
          keywords={keywords}
          imageIDs={imageIDs}
        />
      </div>
    </div>
  )
}

export default ToskPage
