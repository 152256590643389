import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import photoPlaceholder from '../../assets/images/photo_placeholder.svg'
import './Tosk.css'
import MainMenu from '../Menus/MainMenu'
import LogoutMenu from '../Menus/LogoutMenu'
import SaveMenu from '../Menus/SaveMenu'
import Button from '../../components/ui/Button'
import { TOSK_URL } from '../../config'
import UserInfo from '../../components/ui/UserInfo'
import ErrorMessage from '../../components/ui/ErrorMessage'

/**
 * Renders the tosk create page component.
 *
 * @returns {Element} The tosk create page component.
 */
function ToskCreatePage () {
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [tosk, setTosk] = useState('')
  const [submittedTosks, setSubmittedTosks] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [answerReceived, setAnswerReceived] = useState(false)
  const [images, setImages] = useState([
    photoPlaceholder,
    photoPlaceholder,
    photoPlaceholder
  ])
  const [answer, setAnswer] = useState('')
  const [headline1, setHeadline1] = useState('')
  const [headline2, setHeadline2] = useState('')
  const [headline3, setHeadline3] = useState('')
  const [ingress, setIngress] = useState('')
  const [article, setArticle] = useState('')
  const [keywords, setKeywords] = useState('')
  const [imageIDs, setImageIDs] = useState([])
  const [imagePageUrls, setImagePageUrls] = useState([])
  const [imageCreator, setImageCreator] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('access_token')
    if (!token) {
      navigate('/login', { replace: true })
    } else {
      const decodedToken = JSON.parse(atob(token.split('.')[1]))
      setEmail(decodedToken.email)
    }
  }, [navigate])

  /**
   * Handles changes to the tosk input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input event.
   */
  const handleToskChange = (event) => {
    setTosk(event.target.value)
  }

  /**
   * Handles changes to the input fields.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input event.
   */
  const handleInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    const setters = {
      headline1: setHeadline1,
      headline2: setHeadline2,
      headline3: setHeadline3,
      ingress: setIngress,
      article: setArticle
    }
    const setter = setters[name]
    if (setter) {
      setter(value)
    }
  }

  /**
   * Handles the submit of tosk and fetch from API.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event - The button click event.
   * @returns {Promise<void>} A promise that resolves when the fetch request is complete.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    setSubmittedTosks(tosk)
    setTosk('')
    setErrorMessage(null)
    setIsLoading(true)
    try {
      const response = await fetch(`${TOSK_URL}/openai`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },
        body: JSON.stringify({ tosk })
      })
      const data = await response.json()
      setAnswer(data.data.choices[0].message.content)
    } catch (error) {
      console.error(error)
      setErrorMessage('No answer received!')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    try {
      if (answer) {
        setHeadline1(answer.split('HEADLINE1: ')[1].split('HEADLINE2:')[0])
        setHeadline2(answer.split('HEADLINE2: ')[1].split('HEADLINE3:')[0])
        setHeadline3(answer.split('HEADLINE3: ')[1].split('INGRESS:')[0])
        setIngress(answer.split('INGRESS: ')[1].split('ARTICLE:')[0])
        setArticle(answer.split('ARTICLE: ')[1].split('KEYWORDS:')[0])
        setKeywords(answer.split('KEYWORDS: ')[1])
        setAnswerReceived(true)
      }
    } catch (error) {
      console.error(error)
      setErrorMessage('Wrongly coded answer received, please rephrase!')
    }
  }, [answer])

  useEffect(() => {
  /**
   * Fetches images from Pixabay API.
   *
   * return {Promise<void>} A promise that resolves when the fetch request is complete.
   */
    const fetchImages = async () => {
      try {
        const response = await fetch(`${TOSK_URL}/pixabay`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          },
          body: JSON.stringify({ keywords })
        })

        const data = await response.json()
        const imageData = data.data.hits.map((hit) => ({
          id: hit.id,
          webformatURL: hit.webformatURL,
          pageURL: hit.pageURL,
          user: hit.user
        }))
        setImageIDs(imageData.map(hit => hit.id))
        setImages(imageData.map(hit => hit.webformatURL))
        setImagePageUrls(imageData.map(hit => hit.pageURL))
        setImageCreator(imageData.map(hit => hit.user))
        setErrorMessage(null)
      } catch (error) {
        console.error(error)
        setErrorMessage('No images received!')
      }
    }
    if (answerReceived) {
      fetchImages()
    }
  }, [answerReceived, keywords])

  return (
    <div className='tosk-page'>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div className='tosk-page-header'>
        <MainMenu />
        <h1>Create Tosk</h1>
        <UserInfo email={email} />
        <LogoutMenu />
      </div>
      <div className='tosk-page-body'>
        <form onSubmit={handleSubmit}>
          <label>
            <h2>Put all information here:</h2>
            <textarea className='tosk-input' name="tosk" value={tosk} rows={10} onChange={handleToskChange} />
          </label>
          {isLoading && <p>Loading...</p>}
          <div className='tosk-page-body-footer'>
            <Button
              className="primary"
              buttonText="Submit"
              onClick={handleSubmit}
            />
          </div>
        </form>

        {answerReceived && (
        <div className='tosk-container'>
          <h2>Tosk</h2>
          {submittedTosks}

          <h2>Headline:</h2>
          <textarea
            className="tosk-headline"
            name="headline1"
            value={headline1}
            onChange={handleInputChange}
          />
          <textarea
            className="tosk-headline"
            name="headline2"
            value={headline2}
            onChange={handleInputChange}
          />
          <textarea
            className="tosk-headline"
            name="headline3"
            value={headline3}
            onChange={handleInputChange}
          />

          <h2>Ingress:</h2>
          <textarea
            className="tosk-ingress"
            name="ingress"
            value={ingress}
            onChange={handleInputChange}
          />

          <h2>Bread:</h2>
          <textarea
            className="tosk-article"
            name="article"
            value={article}
            rows={20}
            onChange={handleInputChange}
          />

          <h2>Keywords:</h2>
          <textarea
            className="tosk-keywords"
            value={keywords}
            readOnly
          />

          {/* Conditionally show images */}
          {keywords && (
            <div>
              <h2>Image proposals using keyword: {keywords.split(',')[0]} </h2>
              <div className="image-container">
                {images.map((imageUrl, index) => (
                  // <img key={index} src={imageUrl} alt={`${index}`} />
                  <div key={index} className='image-card'>
                    <a href={imagePageUrls[index]} target="_blank" rel="noreferrer">
                      {index + 1}. Credit: {imageCreator[index]}. <br /> Click to download!
                      <img key={index} src={imageUrl} alt={`${index}`} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        )}
      </div>
      <div className='tosk-page-footer'>
        <SaveMenu
          headline1={headline1}
          headline2={headline2}
          headline3={headline3}
          ingress={ingress}
          article={article}
          keywords={keywords}
          imageIDs={imageIDs}
        />
      </div>
    </div>
  )
}

export default ToskCreatePage
