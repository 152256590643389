import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/ui/Button'
import Card from '../../components/ui/Card'
import './Tosk.css'
import MainMenu from '../Menus/MainMenu'
import { TOSK_URL } from '../../config'
import LogoutMenu from '../Menus/LogoutMenu'
import UserInfo from '../../components/ui/UserInfo'
import ErrorMessage from '../../components/ui/ErrorMessage'

/**
 * Renders the tosk list page component.
 *
 * @returns {Element} The tosk list page component.
 */
function ToskListPage () {
  const [email, setEmail] = useState('')
  const [savedTosks, setSavedTosks] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('access_token')
    if (!token) {
      navigate('/login', { replace: true })
    } else {
      const decodedToken = JSON.parse(atob(token.split('.')[1]))
      setEmail(decodedToken.email)
    }
  }, [navigate])

  useEffect(() => {
  /**
   * Fetches tosks from service.
   *
   * return {Promise<void>} A promise that resolves when the fetch request is complete.
   */
    const handleRetrieve = async () => {
      try {
        const response = await fetch(`${TOSK_URL}/tosks`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        })
        if (response.ok) {
          const tosks = await response.json()
          setSavedTosks(tosks)
          localStorage.setItem('savedTosks', JSON.stringify(tosks))
          setErrorMessage(null)
        } else {
          setErrorMessage('Failed to retrieve tosks')
        }
      } catch (error) {
        console.error(error)
        setErrorMessage('Failed to retrieve tosks')
      }
    }
    handleRetrieve()
  }, [])

  /**
   * Handles click on tosk.
   *
   * @param {*} id - The id of the tosk.
   */
  const handleToskClick = (id) => {
    navigate(`/list-tosks/${id}`)
  }

  return (
    <div className='tosk-page'>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div className='tosk-page-header'>
        <MainMenu />
        <h1>Saved Tosks</h1>
        <UserInfo email={email} />
        <LogoutMenu />
      </div>
      <div className='tosk-container'>
        { savedTosks && savedTosks.tosks.map((tosk) => (
          <Card key={tosk.id}>
          <Card.Header>{tosk.headline1}</Card.Header>
          <Card.Body>{tosk.ingress}</Card.Body>
          <Card.Footer>
            <Button
              className="primary"
              buttonText={'View Tosk'}
              onClick={() => handleToskClick(tosk.id)} />
          </Card.Footer>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default ToskListPage
