import React from 'react'
import './Button.css'

/**
 * A button component.
 *
 * @param {object} props - The props object.
 * @param {string} props.buttonText - The text to display on the button.
 * @param {string} props.className - The class name to apply to the button.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @returns {Element} - A button element.
 */
const Button = ({ buttonText, className, onClick }) => {
  return <button className={`button-component ${className}`} onClick={onClick}> {buttonText} </button>
}

export default Button
