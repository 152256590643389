import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/ui/Button'
import Image from '../../components/layout/Image'
import Raitatosk from '../../assets/images/raitatosk_lego_root.png'
import './Root.css'

/**
 * Renders the root page of the Raitatosk web application.
 *
 * @returns {Element} The root page component.
 */
function Root () {
  const navigate = useNavigate()

  return (
    <div className='root-page'>
      <div className='root-header'>
        <h1>Raitatosk</h1>
      </div>
      <div className='root-body'>
        <Image
          src={Raitatosk}
          alt="Raitatosk, lego version of the squirrel Ratatosk in the world tree Yggdrasil"
          className="raitatosk-lego-root"
        />
        <p>Raitatosk is a web application that assists users
        in writing articles by providing an AI-generated first
        draft along with suggestions for headlines, lead paragraph, and genre images.
        </p>
        <p>The app use OpenAI for the AI-generated first draft and Pixabay for the genre images.
          More LLM:s and image sources to be added...</p>
        <p>Raitatosk is named after the squirrel Ratatosk that ran with messages from the roots to the top of the world tree Yggdrasil.
          The extra I puts the focus on AI, and also makes the name pronounced as "Write a Tosk".</p>
        <p>What is a Tosk? It is a combination of text and image proposals.</p>
      </div>
      <div className='root-footer'>
        <Button
          buttonText={'Register account!'}
          className="primary"
          onClick={() => navigate('/register')}
        />
        <Button
          buttonText={'Login'}
          className="primary"
          onClick={() => navigate('/login')}
        />
      </div>
    </div>
  )
}

export default Root
