/**
 * LogoutMenu component that displays a logout button and handles the logout functionality.
 *
 * @module LogoutMenu
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Menu.css'
import Button from '../../components/ui/Button'

/**
 * LogoutMenu component function.
 *
 * @returns {Element} JSX element that displays a logout button.
 */
const LogoutMenu = () => {
  const navigate = useNavigate()

  /**
   * Function that removes the access token from storage.
   *
   */
  const handleLogoutClick = () => {
    localStorage.removeItem('access_token')
    navigate('/login', { replace: true })
  }

  return (
    <div className="menu-component">
      <div className="menu-item-logout">
        <Button
            className="primary"
            buttonText={'Logout'}
            onClick={handleLogoutClick}
        />
      </div>
    </div>
  )
}

export default LogoutMenu
