import React from 'react'
import './Card.css'

/**
 * A card component that can be used to display content in a card format.
 *
 * @param {object} props - The props object containing children and className.
 * @param {React.ReactNode} props.children - The content to be displayed inside the card.
 * @param {string} props.className - The class name to be applied to the card.
 * @returns {Element} - A card component.
 */
function Card ({ children, className }) {
  return <div className={`card ${className}`}>{children}</div>
}

/**
 * A card header component that can be used to display a header in a card format.
 *
 * @param {object} props - The props object containing children and className.
 * @param {React.ReactNode} props.children - The content to be displayed inside the header.
 * @param {string} props.className - The class name to be applied to the header.
 * @returns {Element} - A card header component.
 */
function CardHeader ({ children, className }) {
  return <div className={`card-header ${className}`}>{children}</div>
}

/**
 * A card body component that can be used to display a body in a card format.
 *
 * @param {object} props - The props object containing children and className.
 * @param {React.ReactNode} props.children - The content to be displayed inside the body.
 * @param {string} props.className - The class name to be applied to the body.
 * @returns {Element} - A card body component.
 */
function CardBody ({ children, className }) {
  return <div className={`card-body ${className}`}>{children}</div>
}

/**
 * A card footer component that can be used to display a footer in a card format.
 *
 * @param {object} props - The props object containing children and className.
 * @param {React.ReactNode} props.children - The content to be displayed inside the footer.
 * @param {string} props.className - The class name to be applied to the footer.
 * @returns {Element} - A card footer component.
 */
function CardFooter ({ children, className }) {
  return <div className={`card-footer ${className}`}>{children}</div>
}

Card.Header = CardHeader
Card.Body = CardBody
Card.Footer = CardFooter

export default Card
