/**
 * This is the main entry point of the application.
 * It imports React, ReactDOM, and other necessary modules.
 * It also creates a router using react-router-dom and renders the application.
 * Finally, it registers a service worker for PWA support.
 *
 * @module index
 */

import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import Root from './pages/Root/Root'
import LoginPage from './pages/Login/LoginPage'
import RegisterPage from './pages/Register/RegisterPage'
import ToskCreatePage from './pages/Tosks/ToskCreatePage'
import ToskListPage from './pages/Tosks/ToskListPage'
import ToskPage from './pages/Tosks/ToskPage'

/**
 * The router configuration for the application.
 *
 * @type {object[]}
 * @property {string} path - The URL path for the route.
 * @property {Element} element - The React component to render for the route.
 */
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/register',
    element: <RegisterPage />
  },
  {
    path: '/create-tosk',
    element: <ToskCreatePage />
  },
  {
    path: '/list-tosks',
    element: <ToskListPage />
  },
  {
    path: '/list-tosks/:id',
    element: <ToskPage />
  }
])

/**
 * The root element of the application.
 *
 * @type {ReactDOM.Root}
 */
const root = ReactDOM.createRoot(document.getElementById('root'))

// Render the application using the router.
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// Register a service worker for PWA support.
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/serviceWorker.js').then(function (registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope)
    }, function (err) {
      console.log('ServiceWorker registration failed: ', err)
    })
  })
}
