import React from 'react'
import './Image.css'

/**
 * Image component with the specified source, alternate text and class name.
 *
 * @param {object} props - The props of the image component.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.alt - The alternate text for the image.
 * @param {string} props.className - The class name for the image component.
 * @returns {Element} - The image component.
 */
const Image = ({ src, alt, className }) => {
  return <img src={src} alt={alt} className={`image-component ${className}`} />
}

export default Image
