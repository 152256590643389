import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/ui/Button'
import ErrorMessage from '../../components/ui/ErrorMessage'
import './LoginPage.css'
import { AUTH_URL } from '../../config'

/**
 * Renders the login page component.
 *
 * @returns {Element} The login page component.
 */
function LoginPage () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  /**
   * Checks if the user is already logged in. If so, redirects to the tosk list page.
   *
   * @returns {void}
   */
  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      navigate('/list-tosks', { replace: true })
    } else {
      setIsLoading(false)
    }
  }, [navigate])

  /**
   * Handles changes to the email input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input event.
   */
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  /**
   * Handles changes to the password input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input event.
   */
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  /**
   * Handles key down events on the password input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} e - The keyboard event.
   */
  const handlePasswordKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLoginClick(e)
    }
  }

  /**
   * Handles the login button click event.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e - The button click event.
   * @returns {Promise<void>} A promise that resolves when the login request is complete.
   */
  const handleLoginClick = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${AUTH_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
      const data = await response.json()
      if (response.ok) {
        // Login successful, store token in local storage and redirect
        localStorage.setItem('access_token', data.access_token)
        return navigate('/list-tosks', { replace: true })
      } else {
        setErrorMessage('Login failed!')
      }
    } catch (error) {
      console.error(error)
      setErrorMessage('Login failed!')
    }
  }

  /**
   * Redirects the user to the registration page.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e - The button click event.
   * @returns {void}
   */
  const handleRegisterClick = async (e) => {
    e.preventDefault()
    return navigate('/register', { replace: true })
  }

  return (
    <div className='login-page'>
      <div className='login-page__image'>
      </div>
      <div className='login-page__form'>
        {isLoading
          ? (
            <p>Loading...</p>
            )
          : (
          <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <h2>Welcome to Raitatosk</h2>
            <form>
              <label>
                Username: <br />
                <input type="email" value={email} onChange={handleEmailChange} />
              </label>
              <label>
                Password: <br />
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={handlePasswordKeyDown}
                />
              </label>
            </form>
            <Button
              buttonText="Log in"
              className="primary"
              onClick={handleLoginClick}
            />
            <p>Don't have an account?
              <Button
                buttonText="Register here!"
                className="link"
                onClick={handleRegisterClick}
              />
            </p>
            </>
            )}
      </div>
    </div>
  )
}

export default LoginPage
